@import url(https://fonts.googleapis.com/css?family=GFS+Didot&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

body {
  font-family: 'GFS Didot', 'Times New Roman', Times, serif;
  font-size: 16px;
  background-color: #fefefe;
  color: #483b36; }

html, body {
  width: 100%;
  height: 100%; }

.bg-img {
  position: relative;
  height: 65vh;
  width: 100%;
  background: url(/static/media/alex.24ecd6cc.jpg) center center no-repeat;
  background-size: cover; }
  @media only screen and (max-width: 900px) {
    .bg-img {
      height: 50vh; } }
  .bg-img:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: radial-gradient(#66C6BA, #CE4E27);
    opacity: .5; }

.header-content {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  margin: 0 auto;
  text-align: center; }
  .header-content h1 {
    color: white;
    font-size: 12rem;
    font-family: "Helvetica", "Roboto", sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    margin: 0; }
    @media only screen and (max-width: 900px) {
      .header-content h1 {
        font-size: 4rem; } }
  .header-content hr {
    border: 1px solid white;
    width: 50%;
    margin: 15px auto; }
  .header-content h2 {
    display: inline-block;
    border-top: 1px solid white;
    padding: 3rem;
    font-family: 'GFS Didot', 'Times New Roman', Times, serif;
    font-weight: 700;
    font-size: 2rem;
    color: white;
    letter-spacing: 1px; }
    @media only screen and (max-width: 900px) {
      .header-content h2 {
        font-size: 1rem; } }

main {
  max-width: 700px;
  margin: 65px auto 100px auto; }
  @media only screen and (max-width: 900px) {
    main {
      width: 80%; } }
  main ul {
    list-style-type: none;
    display: flex;
    justify-content: space-around;
    margin: 50px auto;
    padding: 0; }
    main ul a {
      font-size: 1.75rem;
      font-family: "Helvetica", "Roboto", sans-serif;
      text-decoration: none;
      color: #483b36; }
      @media only screen and (max-width: 900px) {
        main ul a {
          font-size: 1rem; } }
      main ul a:hover, main ul a:focus {
        color: #CE4E27;
        outline: none; }
  main hr {
    border: 1px solid #CE4E27;
    width: 100%;
    margin: 0 auto; }
  main p {
    line-height: 1.5em;
    font-size: 1.25rem;
    text-align: justify;
    padding: 0 1rem; }
    @media only screen and (max-width: 900px) {
      main p {
        font-size: 1rem;
        line-height: 1.5rem; } }

